<template>
	<div>
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<!-- <bread-crumbs :crumbs="crumbs" @selected="selected"></bread-crumbs> -->
		<service-content-security></service-content-security>
		<Footer></Footer>
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';
// import BreadCrumbs from '@/components/common/BreadCrumbs.vue';
import ServiceContentSecurity from '../components/ServiceContentSecurity.vue';
import MenuIntro from '@/components/common/MenuIntro.vue';
export default {
	components: {
		MainHeader,
		Footer,
		// BreadCrumbs,
		ServiceContentSecurity,
		MenuIntro,
	},
	data() {
		return {
			crumbs: [
				{ name: 'Home', link: '/' },
				{ name: '서비스', link: '/service' },
				{ name: '모듈러스', link: '/servicesecurity' },
			],
			menuKr: 'FCTS 데이터분석 프레임워크',
			menuEn: 'Data Analysis Framework Modulus',
		};
	},
	methods: {
		selected(crumb) {
			this.$emit('selected', crumb);
			this.$router.push(crumb.link);
		},
	},
};
</script>

<style></style>
